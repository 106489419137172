/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Breadcrumb, Button, Kbd, Modal, Table } from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPlus,
  HiTrash,
} from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import {
  changeInputValues,
  getAllBetaContacts,
  changePage,
  removeBetaContactToDataTable,
  addBetaContactToDataTable,
} from '../../features/allBetaContacts/allBetaContactsSlice'
import { useToast } from '../../hooks/useToast'
import profile_icon from '../../assets/images/profile_icon.svg'
import {
  addBetaContact,
  BetaContact,
  BetaContactAdminForm,
  BetaContactOriginEnum,
  clearBetaContactForm,
  deleteBetaContact,
  ElearningUsageEnum,
  updateBetaContact,
  inviteBetaUser,
} from '../../features/betaContact/betaContactSlice'
// import { FaRegistered } from 'react-icons/fa'
import CopyToClipBoard from '../../components/CopyToClipBoard'
import env from 'react-dotenv'
import { BetaContactModalBody } from '../../components/modal-body/BetaContact'
import { MdEdit } from 'react-icons/md'
import { FaRegistered, FaWpforms } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const BetaContactListPage: FC = function () {
  const {
    error,
    page,
    totalBetaContacts,
    numOfPages,
    search,
    sort,
    searchIsVerified,
    searchIsInvited,
    searchPrevention,
    searchNr,
    searchClimate,
    searchRse,
    searchOrigin,
  } = useTypedSelector((store) => store.allBetaContacts)
  const { error: errorBetaContact } = useTypedSelector(
    (store) => store.betaContact
  )
  const toast = useToast()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllBetaContacts({}))
  }, [
    dispatch,
    page,
    search,
    sort,
    searchIsVerified,
    searchIsInvited,
    searchPrevention,
    searchNr,
    searchClimate,
    searchRse,
    searchOrigin,
  ])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorBetaContact !== '') {
      toast('error', errorBetaContact)
      dispatch(clearBetaContactForm())
    }
    // eslint-disable-next-line
  }, [error, errorBetaContact])

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/beta/register'>Beta</Breadcrumb.Item>
              <Breadcrumb.Item>Liste</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Liste des personnes inscrites pour le contenu beta de LAGOON
            </h1>
          </div>
          <div className='sm:flex'>
            <DataTableController
              placeholder='rechercher un utilisateur'
              handleChange={changeInputValues}
              sort={sort}
              search={search}
              // @ts-ignore
              initFilters={[
                {
                  name: 'searchIsVerified',
                  value: searchIsVerified,
                  control: 'true',
                  text: 'mail vérifiés',
                },
                {
                  name: 'searchIsVerified',
                  value: searchIsVerified,
                  control: 'false',
                  text: 'mail non vérifiés',
                },
                {
                  name: 'searchIsInvited',
                  value: searchIsVerified,
                  control: 'true',
                  text: 'invité',
                },
                {
                  name: 'searchIsVerified',
                  value: searchIsVerified,
                  control: 'false',
                  text: 'pas invité',
                },
                {
                  name: 'searchOrigin',
                  value: searchOrigin,
                  control: BetaContactOriginEnum.ROSELAGON,
                  text: 'formulaire roselagon',
                },
                {
                  name: 'searchOrigin',
                  value: searchOrigin,
                  control: BetaContactOriginEnum.ADMIN,
                  text: 'formulaire admin',
                },
                {
                  name: 'searchPrevention',
                  value: searchPrevention,
                  control: 'true',
                  text: 'contenu prévention',
                },
                {
                  name: 'searchNr',
                  value: searchNr,
                  control: 'true',
                  text: 'contenu NR',
                },
                {
                  name: 'searchClimate',
                  value: searchClimate,
                  control: 'true',
                  text: 'contenu climat',
                },
                {
                  name: 'searchRse',
                  value: searchRse,
                  control: 'true',
                  text: 'contenu RSE',
                },
              ]}
            ></DataTableController>
            <div className='ml-auto flex items-center space-x-2 sm:space-x-3'>
              <AddBetaContact />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <BetaRegistrationsTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalBetaContacts}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const BetaRegistrationsTable: FC = function () {
  const { betaContacts, isLoading } = useTypedSelector(
    (store) => store.allBetaContacts
  )
  const origin = `${env.PROTOCOL}://${env.BETA_CONFIRM_ENDPOINT}`
  // const { user } = useTypedSelector((store) => store.user)
  const castBetaContacts = betaContacts as BetaContact[]
  const navigate = useNavigate()
  if (isLoading) {
    return <Loading center />
  }
  if (betaContacts.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm rounded-lg bg-blue-50 dark:bg-gray-800 text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de liste a afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Identité</Table.HeadCell>
        <Table.HeadCell>Source</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Intéret</Table.HeadCell>
        <Table.HeadCell>eLearning</Table.HeadCell>
        <Table.HeadCell>Emploi</Table.HeadCell>
        <Table.HeadCell>Expertise</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaContacts.map((betaContact) => {
          return (
            <Table.Row
              key={betaContact._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              {/* identité */}
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {betaContact.lastName} {betaContact.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {betaContact.email}
                  </div>
                </div>
              </Table.Cell>
              {/* source */}
              {betaContact.origin === BetaContactOriginEnum.ROSELAGON ? (
                <Table.Cell className='items-center p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div>
                    <p>Formulaire</p>
                    <p>Roselagon</p>
                  </div>
                  {betaContact.communicationChannels &&
                    betaContact.communicationChannels.linkedin && (
                      <Kbd>linkedin</Kbd>
                    )}
                  {betaContact.communicationChannels &&
                    betaContact.communicationChannels.entourage && (
                      <Kbd>entourage</Kbd>
                    )}
                  {betaContact.communicationChannels &&
                    betaContact.communicationChannels.website && (
                      <Kbd>site</Kbd>
                    )}
                  {betaContact.communicationChannels &&
                    betaContact.communicationChannels.other && (
                      <Kbd>{betaContact.communicationChannels.other}</Kbd>
                    )}
                </Table.Cell>
              ) : (
                betaContact.origin === BetaContactOriginEnum.ADMIN && (
                  <Table.Cell className='items-center p-4 text-base font-normal text-gray-900 dark:text-white'>
                    <div>
                      <p>Formulaire</p>
                      <p>Admin</p>
                    </div>
                  </Table.Cell>
                )
              )}
              {/* status */}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                {betaContact.isVerified ? (
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Email Validé
                  </div>
                ) : (
                  <>
                    <div className='text-sm font-normal text-gray-500 dark:text-gray-400 flex'>
                      Lien de validation :
                      <CopyToClipBoard
                        textToCopy={`${origin}?token=${betaContact.verificationToken}&email=${betaContact.email}`}
                      />
                    </div>
                    <div className='flex items-center'>
                      <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                      Email Non Validé
                    </div>
                  </>
                )}
                {betaContact.user ? (
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Invité
                  </div>
                ) : (
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Pas encore invité
                  </div>
                )}
              </Table.Cell>
              {/* interet */}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                <div className='flex items-center'>
                  <div
                    className={`mr-2 h-2.5 w-2.5 rounded-full ${
                      betaContact.preventionContent === true
                        ? 'bg-green-400'
                        : betaContact.preventionContent === false
                        ? 'bg-red-500'
                        : 'bg-gray-500'
                    }`}
                  ></div>
                  <span>Prévention</span>
                </div>
                <div className='flex items-center'>
                  <div
                    className={`mr-2 h-2.5 w-2.5 rounded-full ${
                      betaContact.nrContent === true
                        ? 'bg-green-400'
                        : betaContact.nrContent === false
                        ? 'bg-red-500'
                        : 'bg-gray-500'
                    }`}
                  ></div>
                  <span>NR</span>
                </div>
                <div className='flex items-center'>
                  <div
                    className={`mr-2 h-2.5 w-2.5 rounded-full ${
                      betaContact.climateContent === true
                        ? 'bg-green-400'
                        : betaContact.climateContent === false
                        ? 'bg-red-500'
                        : 'bg-gray-500'
                    }`}
                  ></div>
                  <span>Climat</span>
                </div>
                <div className='flex items-center'>
                  <div
                    className={`mr-2 h-2.5 w-2.5 rounded-full ${
                      betaContact.rseContent === true
                        ? 'bg-green-400'
                        : betaContact.rseContent === false
                        ? 'bg-red-500'
                        : 'bg-gray-500'
                    }`}
                  ></div>
                  <span>RSE</span>
                </div>
              </Table.Cell>
              {/* usage */}
              <Table.Cell className='items-center p-4 text-base font-normal text-gray-900 dark:text-white'>
                <div>
                  {betaContact.elearningUsage === undefined ? (
                    <></>
                  ) : betaContact.elearningUsage ===
                    ElearningUsageEnum.NEVER ? (
                    <p>Jamais</p>
                  ) : ElearningUsageEnum.OFTEN ? (
                    <p>Souvent</p>
                  ) : (
                    ElearningUsageEnum.SOMETIMES && <p>Parfois</p>
                  )}
                </div>
              </Table.Cell>
              {/* emploi */}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                {betaContact.employment && (
                  <>
                    {betaContact.employment.digitalTraining && (
                      <div className='flex items-center'>
                        <span>Formation digitale</span>
                      </div>
                    )}
                    {betaContact.employment.facetofaceTraining && (
                      <div className='flex items-center'>
                        <span>Formation présentielle</span>
                      </div>
                    )}
                    {betaContact.employment.futureChallenges && (
                      <div className='flex items-center'>
                        <span>Enjeux climatiques</span>
                      </div>
                    )}
                    {betaContact.employment.hr && (
                      <div className='flex items-center'>
                        <span>RH</span>
                      </div>
                    )}
                    {betaContact.employment.ceo && (
                      <div className='flex items-center'>
                        <span>CEO</span>
                      </div>
                    )}
                    {betaContact.employment.other && (
                      <div className='flex items-center'>
                        <span className='underline'>
                          {betaContact.employment.other}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Table.Cell>
              {/* addedvalue */}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                {betaContact.addedvalue && (
                  <>
                    {betaContact.addedvalue.educational && (
                      <div className='flex items-center'>
                        <span>Pédagogique</span>
                      </div>
                    )}
                    {betaContact.addedvalue.graphics && (
                      <div className='flex items-center'>
                        <span>Graphisme</span>
                      </div>
                    )}
                    {betaContact.addedvalue.data && (
                      <div className='flex items-center'>
                        <span>Data</span>
                      </div>
                    )}
                    {betaContact.addedvalue.classic && (
                      <div className='flex items-center'>
                        <span>User Classique</span>
                      </div>
                    )}
                    {betaContact.addedvalue.other && (
                      <div className='flex items-center'>
                        <span className='underline'>
                          {betaContact.addedvalue.other}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Table.Cell>
              {/* actions */}
              <Table.Cell>
                <div className='flex items-center gap-x-3 whitespace-nowrap'>
                  {betaContact.betaLagoonFormAnswers && (
                    <Button
                      color='purple'
                      onClick={() =>
                        navigate(`./${betaContact._id}/lagoon-form-answers`)
                      }
                    >
                      <FaWpforms className='mr-2'></FaWpforms>LAGOON
                    </Button>
                  )}
                  {betaContact.betaModuleFormAnswers &&
                    betaContact.betaModuleFormAnswers.length > 0 && (
                      <Button
                        color='purple'
                        onClick={() =>
                          navigate(`./${betaContact._id}/module-form-answers`)
                        }
                      >
                        <FaWpforms className='mr-2'></FaWpforms>Modules
                      </Button>
                    )}
                  <InviteBetaContact betaContact={betaContact} />
                  <EditBetaContact betaContactSelected={betaContact} />
                  {betaContact.user === null && (
                    <RemoveBetaContact betaContact={betaContact} />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const RemoveBetaContact: FC<{ betaContact: BetaContact }> = function ({
  betaContact,
}) {
  const [isOpen, setOpen] = useState(false)
  const { success, isLoading } = useTypedSelector((store) => store.betaContact)

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(deleteBetaContact(betaContact._id))
  }

  useEffect(() => {
    if (success && isOpen) {
      dispatch(removeBetaContactToDataTable(betaContact))
      dispatch(clearBetaContactForm())
      toast('success', `Une personne a été retirée`)
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [success, isOpen])
  return (
    <>
      <Button
        color='failure'
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-2'>
          <HiTrash className='text-lg' />
          Supprimer
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-6 pb-0 pt-6'>
          <span className='sr-only'>Retirer l'administrateur</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-500' />
            <p className='text-xl text-gray-500'>
              Êtes vous sur de vouloir retirer {betaContact.email} des personnes
              inscrites ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur !
              </Button>
              <Button
                color='gray'
                disabled={isLoading}
                onClick={() => {
                  setOpen(false)
                }}
              >
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const AddBetaContact: FC = function () {
  const initialValues: BetaContactAdminForm = {
    name: '',
    lastName: '',
    email: '',
    employment: {
      digitalTraining: false,
      facetofaceTraining: false,
      futureChallenges: false,
      ceo: false,
      other: '',
    },
    addedvalue: { educational: false, data: false, graphics: false, other: '' },
    elearningUsage: undefined,
  }
  const [isOpen, setOpen] = useState(false)
  const { betaContact, isLoading } = useTypedSelector(
    (store) => store.betaContact
  )
  const [values, setValues] = useState<BetaContactAdminForm>(initialValues)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const origin = `${env.PROTOCOL}://${env.BETA_CONFIRM_ENDPOINT}`

  useEffect(() => {
    if (betaContact?._id && isOpen) {
      toast(
        'success',
        `Nouveau contact, le lien de validation a été collé dans le presse papiers`
      )
      navigator.clipboard.writeText(
        `${origin}?token=${betaContact.verificationToken}&email=${betaContact.email}`
      )
      //refresh list
      dispatch(addBetaContactToDataTable(betaContact))
      dispatch(clearBetaContactForm())
      setValues({ ...initialValues })
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, betaContact, isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckEmployment = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const type = e.currentTarget.type
    const checked = e.currentTarget.checked
    const value = e.currentTarget.value

    if (type === 'checkbox') {
      setValues({
        ...values,
        employment: { ...values.employment, [name]: checked },
      })
    } else {
      setValues({
        ...values,
        employment: { ...values.employment, [name]: value },
      })
    }
  }

  const handleCheckAddedValue = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const type = e.currentTarget.type
    const checked = e.currentTarget.checked
    const value = e.currentTarget.value
    if (type === 'checkbox') {
      setValues({
        ...values,
        addedvalue: { ...values.addedvalue, [name]: checked },
      })
    } else {
      setValues({
        ...values,
        addedvalue: { ...values.addedvalue, [name]: value },
      })
    }
  }

  const handleRemoveValue = (e: string) => {
    setValues({ ...values, [e]: undefined })
  }

  const handleDropDownChange = (e: ElearningUsageEnum) => {
    setValues({ ...values, elearningUsage: e })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, lastName, email } = values
    if (name !== '' && lastName !== '' && email !== '') {
      dispatch(addBetaContact(values))
    } else {
      toast('error', `Vous avez besoin de renseigner toutes les informations`)
    }
  }

  return (
    <>
      <Button
        disabled={isLoading}
        color='primary'
        onClick={() => setOpen(!isOpen)}
      >
        <div className='flex items-center gap-x-3'>
          <HiPlus className='text-xl' />
          Ajouter un contact beta
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter un contact beta</strong>
          </Modal.Header>
          <BetaContactModalBody
            values={values}
            handleChange={handleChange}
            handleCheckAddedValue={handleCheckAddedValue}
            handleCheckEmployment={handleCheckEmployment}
            handleDropDownChange={handleDropDownChange}
            handleRemoveValue={handleRemoveValue}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const EditBetaContact: FC<{ betaContactSelected: BetaContact }> = function ({
  betaContactSelected,
}) {
  const initialValues: BetaContactAdminForm = {
    ...betaContactSelected,
  }
  const [isOpen, setOpen] = useState(false)
  const { betaContact, isLoading } = useTypedSelector(
    (store) => store.betaContact
  )
  const [values, setValues] = useState<BetaContactAdminForm>(initialValues)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (betaContact?._id && isOpen) {
      toast('success', `Contact edité !`)
      //refresh list
      dispatch(addBetaContactToDataTable(betaContact))
      dispatch(clearBetaContactForm())
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, betaContact, isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckEmployment = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const type = e.currentTarget.type
    const checked = e.currentTarget.checked
    const value = e.currentTarget.value

    if (type === 'checkbox') {
      setValues({
        ...values,
        employment: { ...values.employment, [name]: checked },
      })
    } else {
      setValues({
        ...values,
        employment: { ...values.employment, [name]: value },
      })
    }
  }

  const handleCheckAddedValue = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const type = e.currentTarget.type
    const checked = e.currentTarget.checked
    const value = e.currentTarget.value
    if (type === 'checkbox') {
      setValues({
        ...values,
        addedvalue: { ...values.addedvalue, [name]: checked },
      })
    } else {
      setValues({
        ...values,
        addedvalue: { ...values.addedvalue, [name]: value },
      })
    }
  }

  const handleRemoveValue = (e: string) => {
    setValues({ ...values, [e]: undefined })
  }

  const handleDropDownChange = (e: ElearningUsageEnum) => {
    setValues({ ...values, elearningUsage: e })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, lastName, email } = values
    if (name !== '' && lastName !== '' && email !== '') {
      dispatch(updateBetaContact(values))
    } else {
      toast('error', `Vous avez besoin de renseigner toutes les informations`)
    }
  }

  return (
    <>
      <Button
        disabled={isLoading}
        color='primary'
        onClick={() => setOpen(!isOpen)}
      >
        <div className='flex items-center gap-x-3'>
          <MdEdit className='text-xl' />
          Editer
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer un contact beta</strong>
          </Modal.Header>
          <BetaContactModalBody
            values={values}
            handleChange={handleChange}
            handleCheckAddedValue={handleCheckAddedValue}
            handleCheckEmployment={handleCheckEmployment}
            handleDropDownChange={handleDropDownChange}
            handleRemoveValue={handleRemoveValue}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const InviteBetaContact: FC<{ betaContact: BetaContact }> = function ({
  betaContact: betaContactSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const { betaContact, isLoading } = useTypedSelector(
    (store) => store.betaContact
  )

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (betaContact?._id && isOpen) {
      toast('success', `Contact ${betaContact.email} a été invité !`)
      //refresh list
      dispatch(addBetaContactToDataTable(betaContact))
      dispatch(clearBetaContactForm())
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, betaContact, isOpen])

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(inviteBetaUser(betaContactSelected))
  }

  return (
    <>
      <Button
        color='purple'
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-2'>
          <FaRegistered className='text-lg' />
          Inviter
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-6 pb-0 pt-6'>
          <span className='sr-only'>Inviter un inscrit</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-yellow-400' />
            <p className='text-xl text-gray-500'>
              Êtes vous sur de vouloir inviter {betaContactSelected.email} à
              rejoindre les beta testeurs de LAGOON ? Un email va lui être
              envoyer pour finaliser son inscription
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='purple' onClick={handleSubmit}>
                Oui, je suis sur !
              </Button>
              <Button
                color='gray'
                disabled={isLoading}
                onClick={() => {
                  setOpen(false)
                }}
              >
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BetaContactListPage

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addBetaContactThunk,
  deleteBetaContactThunk,
  getSingleBetaContactThunk,
  inviteBetaUserThunk,
  updateBetaContactThunk,
} from './betaContactThunk'
import { BetaLagoonFormAnswers } from '../betaLagoonFormAnswers/betaLagoonFormAnswersSlice'
import { BetaModuleFormAnswers } from '../betaModuleFormAnswers/betaModuleFormAnswersSlice'

export enum BetaContactOriginEnum {
  ROSELAGON = 'roselagon',
  ADMIN = 'admin',
}

export enum ElearningUsageEnum {
  NEVER = 'never',
  SOMETIMES = 'sometimes',
  OFTEN = 'often',
}

export interface EmploymentBetaContact {
  digitalTraining?: boolean
  facetofaceTraining?: boolean
  futureChallenges?: boolean
  hr?: boolean
  ceo?: boolean
  other?: string
}

export interface AddedValueBetaContact {
  educational?: boolean
  graphics?: boolean
  data?: boolean
  classic?: boolean
  other?: string
}

export interface CommunicationChannelsBetaContact {
  linkedin?: boolean
  entourage?: boolean
  website?: boolean
  other?: string
}

export interface BetaContact {
  _id: string
  name: string
  lastName: string
  email: string
  verificationToken: string
  origin: BetaContactOriginEnum
  checkDone: boolean
  isVerified: boolean
  verified: Date
  preventionContent?: boolean
  nrContent?: boolean
  climateContent?: boolean
  rseContent?: boolean
  age?: number
  elearningUsage?: ElearningUsageEnum
  employment?: EmploymentBetaContact
  addedvalue?: AddedValueBetaContact
  communicationChannels?: CommunicationChannelsBetaContact
  user?: string
  betaLagoonFormAnswers: BetaLagoonFormAnswers | null
  betaModuleFormAnswers: BetaModuleFormAnswers[] | null
}

export interface BetaContactAdminForm {
  _id?: string
  name: string
  lastName: string
  email: string
  elearningUsage?: ElearningUsageEnum
  employment?: EmploymentBetaContact
  addedvalue?: AddedValueBetaContact
}

export interface BetaContactState {
  isLoading: boolean
  betaContact: BetaContact
  error: string
  success: boolean
}

const initialState: BetaContactState = {
  isLoading: false,
  betaContact: {} as BetaContact,
  error: '',
  success: false,
}

export const getSingleBetaContact = createAsyncThunk(
  'roselagon/betacontacts/get',
  async (id: string, thunkAPI) => {
    return getSingleBetaContactThunk(
      '/roselagon/betacontacts/:id',
      id,
      thunkAPI
    )
  }
)

export const deleteBetaContact = createAsyncThunk(
  'roselagon/betacontacts/delete',
  async (id: string, thunkAPI) => {
    return deleteBetaContactThunk('/roselagon/betacontacts/', id, thunkAPI)
  }
)

export const addBetaContact = createAsyncThunk(
  'roselagon/betacontacts/create-admin',
  async (betaContact: BetaContactAdminForm, thunkAPI) => {
    return addBetaContactThunk(
      'roselagon/betacontacts/create-admin',
      betaContact,
      thunkAPI
    )
  }
)

export const updateBetaContact = createAsyncThunk(
  'roselagon/betacontacts/:id',
  async (betaContact: BetaContactAdminForm, thunkAPI) => {
    return updateBetaContactThunk(
      'roselagon/betacontacts/:id',
      betaContact,
      thunkAPI
    )
  }
)

export const inviteBetaUser = createAsyncThunk(
  'users/invite-beta',
  async (betaContact: BetaContact, thunkAPI) => {
    return inviteBetaUserThunk(
      '/roselagon/betacontacts/',
      betaContact,
      thunkAPI
    )
  }
)

const betaContactSlice = createSlice({
  name: 'betaContact',
  initialState,
  reducers: {
    clearBetaContactForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleBetaContact.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getSingleBetaContact.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { betaContact } = payload
        state.betaContact = betaContact
      })
      .addCase(getSingleBetaContact.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(deleteBetaContact.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(deleteBetaContact.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(deleteBetaContact.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(addBetaContact.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addBetaContact.fulfilled, (state, { payload }) => {
        const { betaContact } = payload
        state.betaContact = betaContact
        state.isLoading = false
      })
      .addCase(addBetaContact.rejected, (state, { payload }) => {
        state.isLoading = false
        state.betaContact = {} as BetaContact
        state.error = payload as string
      })
      .addCase(updateBetaContact.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(updateBetaContact.fulfilled, (state, { payload }) => {
        const { betaContact } = payload
        state.betaContact = betaContact
        state.isLoading = false
      })
      .addCase(updateBetaContact.rejected, (state, { payload }) => {
        state.isLoading = false
        state.betaContact = {} as BetaContact
        state.error = payload as string
      })
      .addCase(inviteBetaUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(inviteBetaUser.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { betaContact } = payload
        state.betaContact = betaContact
      })
      .addCase(inviteBetaUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearBetaContactForm } = betaContactSlice.actions

export default betaContactSlice.reducer
